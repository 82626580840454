<template>
  <div class="cb-message">
    <span
      ref="icon"
      class="cb-message-icon">
      <avatar :is-img="avatarIsImg" :obj="user" :alias="alias" />
    </span>
    <div
      :id="messageRef"
      :ref="messageRef"
      class="cb-message-text py-0 d-flex flex-column">
      <span class="time-hour">
        <span class="font-weight-bold black--text">{{ avatarIsImg ? user.name : name }}</span>
        {{ timeHour }}
      </span>
      <template v-if="data.message !== '' ">
        <template>
          <span class="letters" v-html="data.message" />
          <a
            v-if="data.link"
            href=""
            class="accent--text"
            @click.prevent="action">{{ data.link }}</a>
        </template>
      </template>
      <div
        v-else
        class="cb-message-spinner">
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
      </div>
    </div>
  </div>
</template>

<script>
  import chatUiMixin from '@/mixins/chat-ui'
  import Avatar from './Avatar'

  export default {
    components: { Avatar },
    mixins: [ chatUiMixin ],
    data () {
      return {
        messageRef: `message-${this.record.id}`
      }
    },
    computed: {
      data () {
        return this.record.data
      },
      timeHour () {
        // return location.hostname === 'localhost' ? this.record.slug : ''
        // return this.$d(this.record.insertedAt.toDate(), 'short_date')
        return this.record.time
      },
      avatarIsImg () {
        return (this.record.data.isBot && !!this.user)
      }
    },
    asyncComputed: {
      async user () {
        const doc = this.record.user && await this.record.user
        if (doc) {
          const data = doc.data()
          if (data) {
            return data
          }
        }
        return ''
      }
    },
    mounted () {
      this.iconAnimation()
      this.$nextTick(() => this.messageAnimation(this.messageRef))
    },
    methods: {
      action () {
        this.$emit('action', true)
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'

  $radius: 6px
  $heightFooter: 75px

  .cb-message
    margin-bottom: 30px
    left: 0
    align-self: flex-start
    flex-direction: row
    display: flex
    align-items: center

    a:hover
      text-decoration: none

    &-icon
      transform: translate(-100px)
      opacity: 0
      align-self: flex-start

      svg
        width: 40px
        height: 40px

    &-text
      color: #000000
      padding: 0px 12px
      position: relative
      font-size: 16px
      font-weight: 300

      .letter
        display: inline-block
        line-height: 1em

  .cb-message-spinner,
  .cb-message-spinner *
    box-sizing: border-box

  .cb-message-spinner
    height: 30px
    display: flex
    align-items: center

    .dot
      // width: 10px
      // height: 10px
      margin: 0 5px
      padding: 1px
      border: calc(15px / 5) solid $darkGray
      // background: $darkGray
      border-radius: 50%
      transform: scale(0)
      opacity: 0
      animation: spinner-animation 1000ms ease infinite 0ms

      &:nth-child(1)
        animation-delay: calc(300ms * 1)

      &:nth-child(2)
        animation-delay: calc(300ms * 2)

      &:nth-child(3)
        animation-delay: calc(300ms * 3)

  @keyframes spinner-animation
    50%
      transform: scale(1)
      opacity: 1

    100%
      opacity: 0

  @media screen and (max-width: $max-mobile)
    .cb-message
      &-text
        font-size: 18px

      &-icon
        svg
          width: 40px
          height: 40px

</style>
