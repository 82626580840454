<template>
  <div class="term-option">
    <div
      v-if="!data.input"
      class="chat-term">
      <message class="mb-0" :record="record" />
      <div
        ref="cardTerm"
        class="card-term">
        <v-card flat class="box-card transparent">
          <v-card-text>
            <div :id="pdfViewId" />
          </v-card-text>
        </v-card>

        <p class="ml-5 my-2">
          <a
            href=""
            class="accent--text"
            @click.prevent="openModal">Visualizar Termo</a>
        </p>
      </div>

      <modal
        :style="modelStyle"
        :show="showModal"
        max-width="900px"
        hide-actions
        @close="closeModal">
        <div slot="content">
          <div :id="pdfViewIdModal" />
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import Modal from '@/components/ui/Modal'
  import PDFObject from 'pdfobject'

  export default {
    components: { Message, Modal },
    mixins: [ chatUiMixin ],
    data () {
      return {
        pdfViewId: `pdfView-${this.$uuid.v4()}`,
        pdfViewIdModal: `pdfView-${this.$uuid.v4()}`,
        accept: false,
        showModal: false,
        fileUrl: ''
      }
    },
    computed: {
      data () {
        return this.record.data
      },
      hasAccept () {
        // if (this.data.checkbox) { this.accept = false }
        return this.data.checkbox
      },
      modelStyle () {
        if(this.showModal) {
          return ''
        } else {
          return 'display: none'
        }
      }
    },
    mounted () {
      this.scaleAnimation()
      const storage = this.firebase.storage()
      const pathReference = storage.ref(this.data.termFile)

      pathReference.getDownloadURL().then(fileUrl => {
        this.fileUrl = fileUrl

        const options = { pdfOpenParams: { view: 'FitH', page: '1' } }
        PDFObject.embed(fileUrl, `#${this.pdfViewId}`, options)
        this.scroll()
      })
    },
    methods: {
      send (index, value) {
        let data = {
          slug: this.slug,
          value,
          topic: this.$route.params.caseId
        }
        this.endOptionsAnimation(index, data)
      },
      async startOptionsAnimation () {
        // let children = this.$el.children[0].children
        // let children = this.$refs.buttons
        let children = '.term-option .option-btn'
        // let listTypes = [...children]
        // let listTypes = [this.$refs.btn1, this.$refs.btn2]
        let listTypes = [children]

        await listTypes.map((elem, index) => {
          this
            .anime
            .timeline({ delay: 200 * (index + 1) })
            .add({
              targets: elem,
              duration: 500,
              opacity: [0, 1],
              scale: [0, 1]
            })
        })

        await this.anime({
          targets: '.option-component .v-input--checkbox',
          opacity: [0, 1]
        })
      },
      async endOptionsAnimation (index, option) {
        // let children = this.$el.children[0].children
        // let listTypes = [...children]
        let children = '.term-option .option-btn'
        let listTypes = [children]
        let removedChild = listTypes[index]

        listTypes.splice(index, 1)
        listTypes.unshift(removedChild)

        await listTypes.map((elem, index) => {
          this
            .anime
            .timeline({ delay: 100 * index })
            .add({
              targets: elem,
              opacity: [1, 0],
              scale: [1, 0]
            })
        })

        await this.anime({
          targets: '.option-component .v-input--checkbox',
          opacity: [1, 0]
        })

        this
          .$store
          .dispatch('conversations/answer', option)
          .then(a => {
            console.log(a)
          })
          .catch(err => {
            console.log(err)
            this.scaleAnimation()
          })
      },
      changeTerm (isChecked) {
        this.data.options[0].disabled = !isChecked
      },
      openModal () {
        this.showModal = true
        this.$nextTick(() => {
          const options = { pdfOpenParams: { view: 'FitH', page: '1' } }
          PDFObject.embed(this.fileUrl, `#${this.pdfViewIdModal}`, options)
        })
      },
      closeModal () {
        this.showModal = false
      },
      async scaleAnimation () {
        const cardTerm = this.$refs.cardTerm

        await this.anime({
          targets: cardTerm,
          easing: 'easeInOutQuad',
          scale: [
            { value: 0, duration: 100 },
            { value: 1, duration: 500 }
          ],
          opacity: [
            { value: 0, duration: 100 },
            { value: 1, duration: 200 }
          ]
        }).finished

        await this.startOptionsAnimation()
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/placeholder'

  .option-btn,
  .v-legend,
  .area-checkbox .v-checkbox
    opacity: 0

  // scoped class
  .option-component
    display: flex
    justify-content: center
    flex-wrap: wrap
    max-width: 960px
    padding: 10px 0

    .v-legend
      top: 5px
      position: absolute
      text-align: center
      font-size: 16px
      font-weight: 400

    .v-input--checkbox
      top: 15px
      position: relative

      label
        color: #000

    .v-input--selection-controls
      margin-top: 0
      padding-top: 0

    .option-btn
      margin: 0 5px
      border-radius: 30px
      padding: 0 20px
      height: 50px

  .term-pdf
    width: 100%

  .chat-term
    overflow: hidden

    .card-term
      opacity: 0
      transform: scale(0)
      width: 670px
      margin: 0 auto

      .box-card
        width: 100%
        overflow: hidden

        .v-card__text
          border-radius: 10px


    .v-card
      margin-bottom: 20px
      max-height: 350px
      padding: 10px 0

    .v-card__text
      max-height: 350px
      padding: 10px

      .pdfobject-container
        height: 250px

  @media screen and (max-width: $max-mobile)

    .term-option
      .option-component
        // bottom: -15px
        bottom: 0

        .v-checkbox
          left: 10px
          top: 5px

          label
            font-size: 14px
            line-height: 1.4
            text-overflow: initial
            overflow: inherit
            white-space: initial
            // max-width: 75%

    .chat-term
      .v-card
        max-height: 250px

    .v-modal .v-card__text
      padding: 0!important

</style>
